import { graphql, Link } from 'gatsby'
import React from 'react'
import { BreadCrumbItems } from '../../../../src/common/components/breadCrumb/LocalizedBreadCrumb'
import CTACard from '../../../../src/common/components/CTACard/CTACard'
import Layout from '../../../../src/common/components/Layout'
import RecomendacionCard from '../../components/RecomendacionCard/RecomendacionCard'
import Title from '../../../../src/common/components/Title'
import { findMenuEntry } from '../../constants/menu'
import { useSelectedMenu } from '../../helpers/customHooks/useSelectedMenu'
import { t, textWithLocale } from '../../../../src/common/i18n'
import '../../../../src/common/styles/singlePage.scss'
import route from '../../../../src/common/utils/route'
import styles from '../../../../src/common/templates/plantillas/plantillaLateral.module.scss'

const PageTemplate = ({ data }) => {
  useSelectedMenu(data.distributivas.id_menu_principal)
  const breadCrumItems = [
    { display: t('bread_crumb.inicio'), link: route('index') },
  ] as BreadCrumbItems

  const principal = findMenuEntry(data.distributivas.id_menu_principal)
  if (principal) {
    breadCrumItems.push({
      display: t(principal.name),
      link: route(principal.route),
    })
  }

  breadCrumItems.push({
    display: data.distributivas[textWithLocale('titulo')],
  })

  return (
    <Layout
      selector={false}
      breadCrumItems={breadCrumItems}
      seoData={data.seoData}>
      <div className={styles.ficha_container}>
        <Title text={data.distributivas[textWithLocale('titulo')]}></Title>
        <div className={styles.ficha_container_inferior}>
          <div className={styles.ficha_container_izquierda}>
            <div
              className={styles.ficha_texto_container}
              dangerouslySetInnerHTML={{
                __html: data.distributivas[textWithLocale('intro')],
              }}></div>
            <div className={styles.links_container_xl}></div>
            <div
              className={styles.ficha_texto_container}
              dangerouslySetInnerHTML={{
                __html: data.distributivas[textWithLocale('contenido')],
              }}></div>
          </div>
          <div className={styles.ficha_container_lateral}>
            <RecomendacionCard
              recomendacion={
                data.distributivas[textWithLocale('recomendacion')]
              }
            />
            <CTACard
              description={
                data.distributivas[textWithLocale('cta_descripcion')]
              }
              linkButton={data.distributivas[textWithLocale('cta_link')]}
              textButton={
                data.distributivas[textWithLocale('cta')]
                  ? data.distributivas[textWithLocale('cta')].toUpperCase()
                  : ''
              }
              ctaLink={
                data.distributivas.cta === 'cita_previa'
                  ? {
                      cta: data.distributivas.cta,
                      cta_es: data.distributivas.cta_es,
                      cta_ca: data.distributivas.cta_ca,
                      cta_pt: data.distributivas.cta_pt,
                    }
                  : null
              }
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query distributivaPageQuery($id_site_distributiva: Int!, $url: String!) {
    distributivas(id_site_distributiva: { eq: $id_site_distributiva }) {
      id_site_distributiva
      id_menu_principal
      nombre_es
      nombre_ca
      nombre_pt
      titulo_es
      titulo_pt
      titulo_ca
      intro_ca
      intro_pt
      intro_es
      contenido_ca
      contenido_pt
      contenido_es
      recomendacion_ca
      recomendacion_pt
      recomendacion_es
      cta
      cta_es
      cta_pt
      cta_ca
      cta_descripcion_ca
      cta_descripcion_pt
      cta_descripcion_es
      cta_link_es
      cta_link_pt
      cta_link_ca
    }
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
